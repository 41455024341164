.tg-img-reveal[data-fx='1']::after,
.tg-img-reveal[data-fx='2']::after {
    content: '';
    z-index: -1;
    width: 100%;
    bottom: 0.25rem;
    left: 0;
    position: absolute;
    height: 2px;
    background: currentColor;
    transform: scale3d(0, 1, 1);
    transform-origin: 0% 50%;
    transition: transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
    text-align: center;
}
.tg-img-reveal[data-fx='1']::after,
.tg-img-reveal[data-fx='2']::after {
    bottom: 0;
}
.tg-img-reveal[data-fx='1']:hover::after,
.tg-img-reveal[data-fx='2']:hover::after {
    transform: scale3d(1, 1, 1);
}
.tg-img-reveal[data-fx='6']::after {
    content: '';
    z-index: -1;
    width: 100%;
    bottom: 0.25rem;
    left: 0;
    position: absolute;
    height: 2px;
    background: currentColor;
    transform: scale3d(0, 1, 1);
    transform-origin: 100% 50%;
    transition: transform 0.7s cubic-bezier(0.86, 0, 0.07, 1);
}
.tg-img-reveal[data-fx='6']::after {
    bottom: 0;
}
.tg-img-reveal[data-fx='6']:hover::after {
    transform: scale3d(1, 1, 1);
}
.tg-img-reveal[data-fx='14']::after {
    content: '';
    z-index: -1;
    width: 100%;
    bottom: 0.25rem;
    left: 0;
    position: absolute;
    height: 2px;
    background: currentColor;
    transform: scale3d(0, 1, 1);
    transform-origin: 0% 50%;
}
.tg-img-reveal[data-fx='14']::after {
    bottom: 0;
}
.tg-img-reveal[data-fx='14']:hover::after {
    animation: loop 0.5s infinite;
}
@keyframes loop {
    0% {
        transform-origin: 0% 50%;
        transform: scale3d(0, 1, 1);
    }
    50% {
        transform-origin: 0% 50%;
        transform: scale3d(1, 1, 1);
    }
    51% {
        transform-origin: 100% 50%;
    }
    100% {
        transform-origin: 100% 50%;
        transform: scale3d(0, 1, 1);
    }
}
.tg-img-reveal-wrapper {
    position: fixed;
    width: 400px;
    height: 500px;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    z-index: 9;
    // transform: rotate(-14deg);
}
.tg-img-reveal-wrapper__inner,
.tg-img-reveal-wrapper__img {
    width: 100%;
    height: 100%;
    position: relative;
}
.tg-img-reveal-wrapper__deco {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #181314;
}
.tg-img-reveal-wrapper__img {
    background-size: cover;
    background-position: 50% 50%;
}
.test-2 {
    padding: 50px;
    background-color: #292930;
}
.tg-hover-wrapper {
    display: inline-block;
    background: transparent;
    padding: 35px 30px;
    text-align: center;
}
.tg-hover-wrapper .tgbanner__content-meta {
    margin: 0 0 6px;
    justify-content: center;
    display: none;
}
.tg-hover-wrapper .tg-hover-title {
    margin: 0 0;
    font-size: 20px;
    line-height: 1.4;
}
