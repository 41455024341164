// $variable-prefix: tc- !default;
$prefix: tc- !default;
// user-variables
/*-----------------------------------------------------------------
 --------------- START THEME CUSTOMIZE ----------------------------
-----------------------------------------------------------------*/
// Text Selection Color
::selection {
    background: var(--tc-theme-primary);
    opacity: 0.5;
    color: var(--tc-system-black-1);
}
::-moz-selection {
    background: var(--tc-theme-primary);
    opacity: 0.5;
}
// bold and string font color
b,
strong {
    font-weight: bolder;
    color: var(--tc-theme-primary);
}
// blockquote design
.blockquote {
    background-color: var(--tc-neutral-100);
    padding: 24px 24px 24px 56px;
    border-radius: 12px;
    position: relative;
    color: var(--tc-neutral-500);
    margin: 20px auto;
}
.blockquote::before {
    font-family: 'bootstrap-icons';
    left: 16px;
    top: 4px;
    display: block;
    font-size: 3em;
    content: '\F6B0';
    position: absolute;
    color: var(--tc-neutral-500);
    font-size: 32px;
}
/*# sourceMappingURL=_customize.scss.map */
.m-0 {
    margin: 0 !important;
}
.m-1 {
    margin: 0.25rem !important;
}
.m-2 {
    margin: 0.5rem !important;
}
.m-3 {
    margin: 1rem !important;
}
.m-4 {
    margin: 1.5rem !important;
}
.m-5 {
    margin: 2rem !important;
}
.m-6 {
    margin: 2.5rem !important;
}
.m-7 {
    margin: 3rem !important;
}
.m-8 {
    margin: 4rem !important;
}
.m-9 {
    margin: 5rem !important;
}
.m-10 {
    margin: 6rem !important;
}
.m-auto {
    margin: auto !important;
}
.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}
.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}
.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}
.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}
.mx-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
}
.mx-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
}
.mx-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}
.mx-8 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
}
.mx-9 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
}
.mx-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
}
.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}
.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}
.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}
.my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}
.my-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
}
.my-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}
.my-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
}
.my-9 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}
.my-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
}
.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mt-1 {
    margin-top: 0.25rem !important;
}
.mt-2 {
    margin-top: 0.5rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
.mt-4 {
    margin-top: 1.5rem !important;
}
.mt-5 {
    margin-top: 2rem !important;
}
.mt-6 {
    margin-top: 2.5rem !important;
}
.mt-7 {
    margin-top: 3rem !important;
}
.mt-8 {
    margin-top: 4rem !important;
}
.mt-9 {
    margin-top: 5rem !important;
}
.mt-10 {
    margin-top: 6rem !important;
}
.mt-auto {
    margin-top: auto !important;
}
.me-0 {
    margin-right: 0 !important;
}
.me-1 {
    margin-right: 0.25rem !important;
}
.me-2 {
    margin-right: 0.5rem !important;
}
.me-3 {
    margin-right: 1rem !important;
}
.me-4 {
    margin-right: 1.5rem !important;
}
.me-5 {
    margin-right: 2rem !important;
}
.me-6 {
    margin-right: 2.5rem !important;
}
.me-7 {
    margin-right: 3rem !important;
}
.me-8 {
    margin-right: 4rem !important;
}
.me-9 {
    margin-right: 5rem !important;
}
.me-10 {
    margin-right: 6rem !important;
}
.me-auto {
    margin-right: auto !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}
.mb-2 {
    margin-bottom: 0.5rem !important;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}
.mb-5 {
    margin-bottom: 2rem !important;
}
.mb-6 {
    margin-bottom: 2.5rem !important;
}
.mb-7 {
    margin-bottom: 3rem !important;
}
.mb-8 {
    margin-bottom: 4rem !important;
}
.mb-9 {
    margin-bottom: 5rem !important;
}
.mb-10 {
    margin-bottom: 6rem !important;
}
.mb-auto {
    margin-bottom: auto !important;
}
.ms-0 {
    margin-left: 0 !important;
}
.ms-1 {
    margin-left: 0.25rem !important;
}
.ms-2 {
    margin-left: 0.5rem !important;
}
.ms-3 {
    margin-left: 1rem !important;
}
.ms-4 {
    margin-left: 1.5rem !important;
}
.ms-5 {
    margin-left: 2rem !important;
}
.ms-6 {
    margin-left: 2.5rem !important;
}
.ms-7 {
    margin-left: 3rem !important;
}
.ms-8 {
    margin-left: 4rem !important;
}
.ms-9 {
    margin-left: 5rem !important;
}
.ms-10 {
    margin-left: 6rem !important;
}
.ms-auto {
    margin-left: auto !important;
}
.m-n1 {
    margin: -0.25rem !important;
}
.m-n2 {
    margin: -0.5rem !important;
}
.m-n3 {
    margin: -1rem !important;
}
.m-n4 {
    margin: -1.5rem !important;
}
.m-n5 {
    margin: -2rem !important;
}
.m-n6 {
    margin: -2.5rem !important;
}
.m-n7 {
    margin: -3rem !important;
}
.m-n8 {
    margin: -4rem !important;
}
.m-n9 {
    margin: -5rem !important;
}
.m-n10 {
    margin: -6rem !important;
}
.mx-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
}
.mx-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
}
.mx-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
}
.mx-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
}
.mx-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
}
.mx-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
}
.mx-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
}
.mx-n8 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
}
.mx-n9 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
}
.mx-n10 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
}
.my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
}
.my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
}
.my-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
}
.my-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
}
.my-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
}
.my-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
}
.my-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
}
.my-n8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
}
.my-n9 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
}
.my-n10 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
}
.mt-n1 {
    margin-top: -0.25rem !important;
}
.mt-n2 {
    margin-top: -0.5rem !important;
}
.mt-n3 {
    margin-top: -1rem !important;
}
.mt-n4 {
    margin-top: -1.5rem !important;
}
.mt-n5 {
    margin-top: -2rem !important;
}
.mt-n6 {
    margin-top: -2.5rem !important;
}
.mt-n7 {
    margin-top: -3rem !important;
}
.mt-n8 {
    margin-top: -4rem !important;
}
.mt-n9 {
    margin-top: -5rem !important;
}
.mt-n10 {
    margin-top: -6rem !important;
}
.me-n1 {
    margin-right: -0.25rem !important;
}
.me-n2 {
    margin-right: -0.5rem !important;
}
.me-n3 {
    margin-right: -1rem !important;
}
.me-n4 {
    margin-right: -1.5rem !important;
}
.me-n5 {
    margin-right: -2rem !important;
}
.me-n6 {
    margin-right: -2.5rem !important;
}
.me-n7 {
    margin-right: -3rem !important;
}
.me-n8 {
    margin-right: -4rem !important;
}
.me-n9 {
    margin-right: -5rem !important;
}
.me-n10 {
    margin-right: -6rem !important;
}
.mb-n1 {
    margin-bottom: -0.25rem !important;
}
.mb-n2 {
    margin-bottom: -0.5rem !important;
}
.mb-n3 {
    margin-bottom: -1rem !important;
}
.mb-n4 {
    margin-bottom: -1.5rem !important;
}
.mb-n5 {
    margin-bottom: -2rem !important;
}
.mb-n6 {
    margin-bottom: -2.5rem !important;
}
.mb-n7 {
    margin-bottom: -3rem !important;
}
.mb-n8 {
    margin-bottom: -4rem !important;
}
.mb-n9 {
    margin-bottom: -5rem !important;
}
.mb-n10 {
    margin-bottom: -6rem !important;
}
.ms-n1 {
    margin-left: -0.25rem !important;
}
.ms-n2 {
    margin-left: -0.5rem !important;
}
.ms-n3 {
    margin-left: -1rem !important;
}
.ms-n4 {
    margin-left: -1.5rem !important;
}
.ms-n5 {
    margin-left: -2rem !important;
}
.ms-n6 {
    margin-left: -2.5rem !important;
}
.ms-n7 {
    margin-left: -3rem !important;
}
.ms-n8 {
    margin-left: -4rem !important;
}
.ms-n9 {
    margin-left: -5rem !important;
}
.ms-n10 {
    margin-left: -6rem !important;
}
.p-0 {
    padding: 0 !important;
}
.p-1 {
    padding: 0.25rem !important;
}
.p-2 {
    padding: 0.5rem !important;
}
.p-3 {
    padding: 1rem !important;
}
.p-4 {
    padding: 1.5rem !important;
}
.p-5 {
    padding: 2rem !important;
}
.p-6 {
    padding: 2.5rem !important;
}
.p-7 {
    padding: 3rem !important;
}
.p-8 {
    padding: 4rem !important;
}
.p-9 {
    padding: 5rem !important;
}
.p-10 {
    padding: 6rem !important;
}
.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}
.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}
.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}
.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}
.px-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
}
.px-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
}
.px-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}
.px-8 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
}
.px-9 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
}
.px-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
}
.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}
.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}
.py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}
.py-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
}
.py-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}
.py-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}
.py-9 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}
.py-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}
.pt-0 {
    padding-top: 0 !important;
}
.pt-1 {
    padding-top: 0.25rem !important;
}
.pt-2 {
    padding-top: 0.5rem !important;
}
.pt-3 {
    padding-top: 1rem !important;
}
.pt-4 {
    padding-top: 1.5rem !important;
}
.pt-5 {
    padding-top: 2rem !important;
}
.pt-6 {
    padding-top: 2.5rem !important;
}
.pt-7 {
    padding-top: 3rem !important;
}
.pt-8 {
    padding-top: 4rem !important;
}
.pt-9 {
    padding-top: 5rem !important;
}
.pt-10 {
    padding-top: 6rem !important;
}
.pe-0 {
    padding-right: 0 !important;
}
.pe-1 {
    padding-right: 0.25rem !important;
}
.pe-2 {
    padding-right: 0.5rem !important;
}
.pe-3 {
    padding-right: 1rem !important;
}
.pe-4 {
    padding-right: 1.5rem !important;
}
.pe-5 {
    padding-right: 2rem !important;
}
.pe-6 {
    padding-right: 2.5rem !important;
}
.pe-7 {
    padding-right: 3rem !important;
}
.pe-8 {
    padding-right: 4rem !important;
}
.pe-9 {
    padding-right: 5rem !important;
}
.pe-10 {
    padding-right: 6rem !important;
}
.pb-0 {
    padding-bottom: 0 !important;
}
.pb-1 {
    padding-bottom: 0.25rem !important;
}
.pb-2 {
    padding-bottom: 0.5rem !important;
}
.pb-3 {
    padding-bottom: 1rem !important;
}
.pb-4 {
    padding-bottom: 1.5rem !important;
}
.pb-5 {
    padding-bottom: 2rem !important;
}
.pb-6 {
    padding-bottom: 2.5rem !important;
}
.pb-7 {
    padding-bottom: 3rem !important;
}
.pb-8 {
    padding-bottom: 4rem !important;
}
.pb-9 {
    padding-bottom: 5rem !important;
}
.pb-10 {
    padding-bottom: 6rem !important;
}
.ps-0 {
    padding-left: 0 !important;
}
.ps-1 {
    padding-left: 0.25rem !important;
}
.ps-2 {
    padding-left: 0.5rem !important;
}
.ps-3 {
    padding-left: 1rem !important;
}
.ps-4 {
    padding-left: 1.5rem !important;
}
.ps-5 {
    padding-left: 2rem !important;
}
.ps-6 {
    padding-left: 2.5rem !important;
}
.ps-7 {
    padding-left: 3rem !important;
}
.ps-8 {
    padding-left: 4rem !important;
}
.ps-9 {
    padding-left: 5rem !important;
}
.ps-10 {
    padding-left: 6rem !important;
}
